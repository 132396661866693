import React, { /* useEffect, */ useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import api from "../../api";
// import config from '../../config';
import uiConfig from '../../uiConfig';
import Button from '@material-ui/core/Button';
import { useFeedback } from "../feedback/Service";
import { inject } from "mobx-react";
import EvaluationDialog from "./EvaluationDialog";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseButton from "../CloseButton";
import { useHistory, useLocation } from "react-router";
import RecordingDetails from "../RecordingDetails";
import Video from "../quiz/Video";
import { Typography } from "@material-ui/core";

// const SEND_INTERVAL = 30 * 1000;

const useStyles = makeStyles(theme => ({
  content: {
    padding: "0 !important",
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
    }
  },
  details: {
    padding: theme.spacing(1.5, 2, 4, 2),
  }
}));

const RecordingDialog = ({ hide, video, user, portal }) => {
  const [showEvaluationForm, setShowEvaluationForm] = useState(false);
  const ratio = parseFloat(video.ratio);
  const feedback = useFeedback();
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();

  const handleAsk = async () => {
    const question = await feedback.form({ title: "Ask the Speaker", input: { title: 'Your Question', type: "text", name: "text", rows: 5, variant: "outlined" } });
    if (!question)
      return;
    await api.askSpeaker(video.id, question);
    feedback.snackbar({ type: "success", text: "Your question was sent to the speaker. You'll get your reply by email." })
  }

  const handleClose = () => {
    history.push(pathname.replace("/" + pathname.split("/").pop(), ""));
    hide();
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  let src = `https://slidespiel.com/view/${video.alias}`;
  if (user)
    src += `?trackingEmail=${user.email}`;

  return (
    <>
      {uiConfig.showRecordingEvaluation &&
        <EvaluationDialog open={showEvaluationForm} recording={video} handleClose={() => setShowEvaluationForm(false)} entity="recording" />
      }
      <Dialog open onClose={handleClose} maxWidth="md" fullWidth fullScreen={fullScreen} disableScrollLock scroll={fullScreen ? "paper" : "body"}>
        {fullScreen && <CloseButton onClick={handleClose} />}
        <DialogContent className={classes.content}>
          <Video src={src} ratio={ratio} />
          <div className={classes.details}>
            <Typography variant="h6" gutterBottom>{video.name}</Typography>
            <RecordingDetails recording={video} />
          </div>
        </DialogContent>
        {((uiConfig.showRecordingEvaluation || uiConfig.showAskSpeaker) && user) &&
          <DialogActions>
            {uiConfig.showAskSpeaker &&
              <Button color="primary" onClick={handleAsk}>Ask the Speaker</Button>
            }
            {(uiConfig.showRecordingEvaluation && video.evaluationFormMetaData) &&
              <Button color="primary" onClick={() => setShowEvaluationForm(true)}>Evaluate</Button>
            }
          </DialogActions>
        }
      </Dialog>
    </>
  )
}

export default inject(({ store }, props) => ({
  portal: store.portal, store
}))(RecordingDialog);
