import React, { useEffect, useMemo, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import uiConfig from '../uiConfig';
import api from '../api';
import { inject } from 'mobx-react';

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const BasicUserFields = ({ size = "sm", onChange, state, errors, setErrors, portal, excludeEmailAndPassword = false }) => {
  // const [emailError, setEmailError] = useState(null);
  const emailError = useMemo(() => errors.email);
  const setEmailError = useCallback(error => {
    setErrors({ email: error })
  }, [setErrors]);

  useEffect(() => {
    if (!state.email)
      return;
    const checkUser = async () => {
      if (!validateEmail(state.email)) {
        setEmailError("Invalid email address");
        return;
      }
      const userExists = await api.checkUser({ email: state.email, portalId: portal.id });
      setEmailError(userExists ? "User already exists" : null)
    }
    const timeout = setTimeout(checkUser, 400);
    return () => {
      clearTimeout(timeout);
    }
  }, [portal, state.email]);

  return (
    <>
      <Grid item xs={12} sm={6} md={size === "sm" ? 6 : 3}>
        <TextField
          autoFocus
          label="First Name"
          fullWidth
          name="firstName"
          value={state.firstName}
          onChange={onChange}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} md={size === "sm" ? 6 : 3}>
        <TextField
          label="Last Name"
          fullWidth
          name="lastName"
          value={state.lastName}
          onChange={onChange}
          required
        />
      </Grid>
      {!excludeEmailAndPassword &&
        <>
          <Grid item xs={12} sm={6} md={size === "sm" ? 6 : 3}>
            <TextField
              name="email"
              label="Email"
              type="email"
              fullWidth
              value={state.email}
              onChange={onChange}
              helperText={emailError || uiConfig.emailHelperText || undefined}
              required
              error={!!emailError}
            />
          </Grid>
          {portal?.passwordAuth &&
            <Grid item xs={12} sm={6} md={size === "sm" ? 6 : 3}>
              <TextField
                name="password"
                label="Password"
                type="password"
                fullWidth
                value={state.password || ""}
                onChange={onChange}
                required
              />
            </Grid>
          }
        </>
      }
    </>
  );
}

export default inject(({ store }, props) => ({
  portal: store.portal
}))(BasicUserFields);