/* eslint-disable */
const config = {
  // apiHost: "https://slidespielportalsapi.azurewebsites.net",
  apiHost: "https://slidespielportalsapitest.azurewebsites.net",
  spmApiHost: "https://slidespielspeakersapi.azurewebsites.net",
  chatRealm: "https://chat.slidespiel.com",
  env: "development",
  portalName: "acca-ce",
  title: "ACCA: Annual Meeting",
  portals: [
    { year: 2022, id: "277db939-d08e-43a5-869d-9035037914fd" },
  ],
  defaultYear: 2022,
  version: "1.6.0",
};

if (process.env.NODE_ENV === "production") {
  Object.assign(config, {
    apiHost: "https://slidespielportalsapi.azurewebsites.net",
    env: "production",
    sentryDsn: "https://66ebec47e0014dccb50af5e51f5348eb@o361175.ingest.sentry.io/5264442",
  });
}


export default config;
