import React from 'react';
import { inject } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import draftToHtml from 'draftjs-to-html';
// import config from '../config';
import uiConfig from '../uiConfig';
import { getFullPriceString } from '../utils';

const parseSummary = text => {
  try {
    return draftToHtml(JSON.parse(text));
  }
  catch {
    return text;
  }
}

const RecordingDetails = ({ portal, recording }) => {
  const { speakers, summary, ceHours, goals, ceTypeName, alias, price, priceForMembers } = recording;
  const speakersString = speakers && speakers.map((speaker) => speaker.firstName + " " + speaker.lastName).join(", ");
  return (
    <>
      {!alias && <Typography variant="body1" gutterBottom><strong>{getFullPriceString(price, priceForMembers)}</strong></Typography>}
      {(portal.hasCertification && (ceHours || ceTypeName)) &&
        <Typography variant="subtitle1" gutterBottom>
          {ceHours ? `${ceHours} Hours ${ceTypeName || ""} Credit(s)` : "No " + uiConfig.certTitle}
        </Typography>
      }
      <Typography variant="body1" gutterBottom>
        <strong>Speakers:</strong> {speakersString}
      </Typography>
      {summary &&
        <>
          <Typography variant="body1" gutterBottom component="span">
            <strong>Details: </strong>
          </Typography>
          <Typography dangerouslySetInnerHTML={{ __html: parseSummary(summary) }} gutterBottom component="span" />
        </>}
      {goals &&
        <Typography>
          <b>Learning Goals & Objectives:</b>
          <br />
          {goals}
        </Typography>
      }
    </>
  );
}

export default inject(({ store }, props) => ({
  portal: store.portal, user: store.currentUser,
}))(RecordingDetails);