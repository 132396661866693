import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Video from './Video';

import { makeStyles } from '@material-ui/core/styles';

const getSrc = (alias) => {
  return `https://slidespiel.com/view/${alias}`;
};

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(1),
    // float: 'right'
  },
}));

const QuizCertificate = ({email, accountLink, goBackLink, recording}) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6" gutterBottom>Congratulations!</Typography>
      <Typography>You will receive a confirmation at your email <b>{email}</b>.</Typography>
      <Typography>You also can download PDF in the Certificates area of this platform</Typography>
      <br />
      <br />
      <div>
        <Button variant="contained" color="secondary" to={goBackLink} component={Link}>Go Back</Button>
        <Button variant="contained" color="primary" className={classes.button} to={accountLink} component={Link}>Certificates</Button>
      </div>
      <br />
      <Video src={getSrc(recording.alias)} ratio={recording.ratio} />
    </>
  );
};

QuizCertificate.propTypes = {
  email: PropTypes.string.isRequired,
  accountLink: PropTypes.string.isRequired,
  goBackLink: PropTypes.string.isRequired
};

export default QuizCertificate;
