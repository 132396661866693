import { Button, LinearProgress } from '@material-ui/core';
import { inject } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import BasicUserFields from '../BasicUserFields';
import CustomFormFields from '../CustomFormFields';
import useFormMetaData from '../hooks/useFormMetaData';
import { Grid } from "@material-ui/core";
import { validateCustomFields } from '../../utils';
import api from "../../api";
import { useFeedback } from '../feedback/Service';

const EditUserForm = ({ portal, user, onClose, store }) => {
  const [isPosting, setIsPosting] = useState(false);

  const userData = useMemo(() => {
    const { firstName, lastName } = user;
    let res = { firstName, lastName };
    if (!user?.signupFormData)
      return res
    // console.log("user?.signupFormData", JSON.parse(user.signupFormData));
    return { ...JSON.parse(user.signupFormData), ...res };
  }, [user]);

  // const userFields = useMemo(() => Object.keys(userData), [userData]);

  const {
    metaData: portalFormMetaData,
    state: portalState,
    onChange: onPortalStateChange,
    setState: setPortalState,
    setErrors: setPortalErrors,
    errors: portalErrors
  } = useFormMetaData({
    metaDataJson: portal.signUpFormMetaData,
    hasBasicUserFields: true,
    fieldsToExclude: [],
    initialData: userData,
  });

  const feedback = useFeedback();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, ...data } = { ...portalState };
    if (!validateCustomFields(portalFormMetaData, portalState, setPortalErrors))
      return;
    delete data.email;
    delete data.password;
    // console.log({ data });
    setIsPosting(true);
    try {
      await api.updateProfile({ firstName, lastName, signupFormData: JSON.stringify(data) });
      const user = await api.getUser("me", null, store.portalName);
      // console.log({ user });
      store.setUser(user);
      feedback.snackbar({ text: "Changes Saved", type: "success" });
      onClose();
    } catch (e) {
      feedback.snackbar({ text: e.response?.data?.message || "Unknown error", type: "error" });
      console.error(e);
    }
    setIsPosting(false);
  }

  // console.log({ portalState, userData })

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <BasicUserFields state={portalState} onChange={onPortalStateChange} size={portalFormMetaData?.size} errors={portalErrors} setErrors={setPortalErrors} excludeEmailAndPassword />
        <CustomFormFields metaData={portalFormMetaData} state={portalState} errors={portalErrors} setState={setPortalState} setErrors={setPortalErrors} />
        <Grid item xs={12} />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isPosting}
        >
          Save Changes
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          disabled={isPosting}
          onClick={onClose}
          style={{ marginLeft: 8 }}
        >
          Cancel
        </Button>
        {isPosting && <Grid item xs={12}><LinearProgress variant='indeterminate' /></Grid>}
      </Grid>
    </form>
  );
}

export default inject(({ store }, props) => ({
  portal: store.portal, store, user: store.currentUser
}))(EditUserForm);