import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { Provider } from "mobx-react";
import { StripeProvider } from 'react-stripe-elements';
import * as Sentry from '@sentry/browser';

import configureStore from "./store/appStore";

import Main from './components/Main';

import QuizPage from "./components/pages/QuizPage";

import HomePage from "./components/pages/HomePage";
import SignInDialog from "./components/dialogs/SignInDialog";
// import BuyProductDialog from "./components/dialogs/BuyProductDialog";
import SignUpDialog from "./components/dialogs/SignUpDialog";
import TrackPage from "./components/pages/TrackPage";
import ChatPage from "./components/pages/ChatPage";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import AccountDialog from "./components/dialogs/AccountDialog";
import CertificatesDialog from "./components/dialogs/CertificatesDialog";

import uiConfig from "./uiConfig";
import { FeedbackProvider } from './components/feedback/Service';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import SecuredRoute from './components/SecuredRoute';
// import Members from "./components/pages/Members";
import Admin from "./components/pages/Admin";
import config from './config';
import EventsRoot from "./components/EventsRoot";
// import AmplitudeWrapper from "./components/AmplitudeWrapper";
import { CaptureConsole } from '@sentry/integrations';
import { SignUpAndBuyDialogRoute } from "./components/dialogs/SignUpAndBuyDialog";
import ScrollToTop from "./ScrollToTop";
import ResetPasswordDialog from "./components/dialogs/ResetPasswordDialog";
import { reaction } from "mobx";
import { loadStripe } from '@stripe/stripe-js';

const store = configureStore();

store.setIsLoggingIn(true);

console.log("[SLIDESPIEL WEBSITE]", "version", config.version.substring(0, 3));

if (config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
    integrations: [
      new CaptureConsole({
        levels: ['error'],
      })
    ],
    release: "slidespiel@" + config.version.substring(0, 3),
  });
}

const palette = {
  primary: {
    main: uiConfig.mainColor,
  },
};
if (uiConfig.primaryContrastText)
  palette.primary.contrastText = uiConfig.primaryContrastText;
if (uiConfig.secondaryColor) {
  palette.secondary = { main: uiConfig.secondaryColor };
  if (uiConfig.secondaryContrastText)
    palette.secondary.contrastText = uiConfig.secondaryContrastText;
}
//console.log(palette);
const theme = createTheme({ palette });

const years = config.portals.map(x => x.year).join('|');
const yearParam = `/:year(${years})?`;

const App = () => {
  const [stripe, setStripe] = useState(null);

  useEffect(() => { document.title = config.title }, []);

  useEffect(() => {
    const dispose = reaction(() => store.portal?.stripePublicKey, async key => {
      if (!key)
        return;
      try {
        const stripe = await loadStripe(key);
        setStripe(stripe);
      } catch (error) {
        console.error("Stripe load error", error);
      }
    });
    return () => {
      dispose();
    }
  }, [store])

  // console.log({ stripe });
  return (
    <Provider store={store}>
      <StripeProvider stripe={stripe}>
        {/* <AmplitudeWrapper> */}
        <BrowserRouter>
          <ScrollToTop />
          <ThemeProvider theme={theme}>
            <FeedbackProvider>
              <Main>
                <Switch>
                  <SecuredRoute path={yearParam + "/quizzes/:id"} component={QuizPage} secured />
                  <SecuredRoute path={yearParam + "/users-management"} component={Admin} secured roles={["manager"]} />
                  <SecuredRoute path={yearParam + "/admin"} component={Admin} secured roles={["manager"]} />
                  {uiConfig.showOnDemand && <SecuredRoute path={yearParam + "/on-demand/:trackId([a-z0-9-]{36})?/:recordingId([a-z0-9-]{36})?"} component={TrackPage} secured={uiConfig.requireAuth} />}
                  <SecuredRoute path={yearParam + "/event/:id"} component={EventsRoot} />
                  <SecuredRoute path={yearParam + "/pages/:id"} component={EventsRoot} />
                  {uiConfig.showPrivacyPolicy && <SecuredRoute path={yearParam + "/privacy"} component={PrivacyPolicy} />}
                  <SecuredRoute path={yearParam} component={HomePage} />
                </Switch>
                <SecuredRoute path={yearParam + "/(.*)?/login"} component={SignInDialog} />
                <SecuredRoute path={yearParam + "/reset-password"} component={ResetPasswordDialog} />
                {uiConfig.showSignUp &&
                  <SecuredRoute exact path={yearParam + "/(.*)?/sign-up"}>
                    <SignUpDialog open />
                  </SecuredRoute>
                }
                {uiConfig.enableFreeProducts &&
                  <SecuredRoute exact path={yearParam + "/(.*)?/register/:freeProductId"}>
                    <SignUpDialog open />
                  </SecuredRoute>
                }
                <SecuredRoute path={yearParam + "/(.*)?/account"} component={AccountDialog} secured />
                <SecuredRoute path={yearParam + "/(.*)?/certificates"} component={CertificatesDialog} secured />
                <SecuredRoute path={yearParam + "/(.*)?/chat"} component={ChatPage} secured />
                {/* <SecuredRoute path={yearParam + "/(.*)?/buy/:productId"} component={BuyProductDialog} /> */}
                <SecuredRoute path={yearParam + "/(.*)?/buy"}>
                  <SignUpAndBuyDialogRoute />
                </SecuredRoute>
              </Main>
            </FeedbackProvider>
          </ThemeProvider>
        </BrowserRouter>
        {/* </AmplitudeWrapper> */}
      </StripeProvider>
    </Provider>
  );
};

export default App;
