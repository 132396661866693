import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { inject } from "mobx-react";

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { useAmplitude } from "react-amplitude-hooks";

import api from "../../api";
import usePortal from "../hooks/usePortal";
import { useFeedback } from "../feedback/Service";
import uiConfig from "../../uiConfig";
import CloseButton from "../CloseButton";
import LoadingButton from "../LoadingButton";
import SupportLink from "../SupportLink";

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    // marginBottom: theme.spacing(1),
  },
  close: {
    padding: theme.spacing(0.5),
  },
}));

const SignInDialog = ({ store, portal }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  // const { logEvent } = useAmplitude({ page: "login" });
  const feedback = useFeedback();
  const history = useHistory();
  const { portalName: name, portalId, urlPrefix, uiSettings } = usePortal();
  const classes = useStyles();
  // const redirectUrl = urlPrefix || "/";
  const { pathname } = useLocation();
  // const redirectUrl = pathname === "/login" ? urlPrefix + uiSettings.signInRedirect : pathname.replace("/login", "");
  const redirectUrl = urlPrefix + uiSettings.signInRedirect;
  const backUrl = (pathname === "/login" || pathname.includes("event/")) ? urlPrefix : pathname.replace("/login", "");

  const handleClose = () => history.push(backUrl);
  const handleSubmit = async e => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    try {
      const result = await api.login(email, password, portalId);
      localStorage.setItem(`${name}-access_token`, result.access_token);
      const user = await api.getUser("me", result.access_token)
      store.setUser(user);
      await store.fetchEvents()
      setLoading(false);
      history.push(redirectUrl);
      return;
    } catch (error) {
      console.warn("LOGIN ERROR", error);
      feedback.snackbar({ text: error.response?.data?.message || error.response?.data?.error_description || "Unknown error", type: "error" });
    }
    setLoading(false);
  }
  const handleResetPassword = async e => {
    e.preventDefault();
    const email = await feedback.form({ title: "Reset Password", input: { title: 'Email', type: "email", name: "email" } });
    console.log(email);
    if (loading) return;
    if (!email) return;
    setLoading(true);
    try {
      await api.sendPasswordResetLink(email, portalId);
      feedback.snackbar({ text: "Success! You will get an email with password recovery instructions.", type: "success" })
    }
    catch (ex) {
      console.warn(ex.response);
      feedback.snackbar({ text: ex.response?.data?.message || "Unknown error, please contact support", type: "error" });
    }
    setLoading(false);
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open onClose={handleClose} aria-labelledby="sign-in-dialog-title" fullWidth fullScreen={fullScreen}>
      {fullScreen && <CloseButton onClick={handleClose} />}
      <DialogTitle id="sign-in-dialog-title">Sign In</DialogTitle>
      <DialogContent>
        {uiConfig.loginText &&
          <DialogContentText>
            {uiConfig.loginText}<br />
          </DialogContentText>}
        <form onSubmit={handleSubmit} id="sign-in-form" className={classes.form}>
          <TextField
            variant="outlined"
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
          {portal?.passwordAuth &&
            <>
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                onChange={e => setPassword(e.target.value)}
                value={password}
              />
              <Typography variant="body2">
                <Link href="#reset" onClick={handleResetPassword}>Forgot password?</Link>
              </Typography>
            </>
          }
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            loading={loading}
          >
            Sign In
          </LoadingButton>
        </form>
      </DialogContent>
      <DialogActions>
        <SupportLink />
        <div style={{ width: '100%' }} />
      </DialogActions>
    </Dialog>
  );
}

SignInDialog.propTypes = {
  store: PropTypes.object.isRequired
};


export default inject(({ store }, props) => ({
  portal: store.portal, store
}))(SignInDialog);
