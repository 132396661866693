import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation, useParams } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import usePortal from './hooks/portal';
import draftToHtml from 'draftjs-to-html';
import CertIcon from '@material-ui/icons/CheckCircle';
import { inject } from 'mobx-react';
import config from '../uiConfig';
import { useFeedback } from './feedback/Service';
import Disclaimer from './Disclaimer';
import { Box } from '@material-ui/core';
import { formatPrice, getPriceString, getPriceTooltipString } from '../utils';

const parseSummary = text => {
  try {
    return draftToHtml(JSON.parse(text));
  }
  catch {
    return text;
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(1),
  },
  image: {
    width: "100%",
    borderRadius: theme.spacing(1),
    minHeight: 50,
  },
  card: {
    //maxWidth: 345,
    //minWidth: 300
  },
  media: {
    // height: 0,
    //paddingTop: '75%', //'56.25%', // 16:9
    cursor: 'pointer',
    position: 'relative',
    "& > img": {
      width: "100%",
    }
  },
  title: {
    fontWeight: 600,
    fontSize: '0.9rem',
    cursor: 'pointer',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
  },
  summary: {
    // maxHeight: 115,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical',
    cursor: 'pointer',
    "& > p": {
      margin: 0,
    }
  },
  speakers: {
    // maxHeight: 100,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    cursor: 'pointer',
  },
  // priceSpan: {
  //   position: 'absolute',
  //   bottom: 3.5,
  //   right: 0,
  //   width: 0,
  //   height: 0,
  //   borderStyle: 'solid',
  //   borderWidth: `0 0 ${theme.spacing(16)}px ${theme.spacing(16)}px`,
  //   borderColor: `transparent transparent ${theme.palette.primary.main} transparent`
  // },
  price: {
    position: 'absolute',
    bottom: theme.spacing(1.5),
    right: theme.spacing(1),
    color: theme.palette.primary.contrastText,
    backgroundColor: "rgba(0,0,0,0.6)",
    padding: theme.spacing(0.5, 1),
    // color: 'fff'
  },
  subheader: {
    position: 'absolute',
    top: theme.spacing(1.5),
    left: theme.spacing(1),
    color: theme.palette.primary.contrastText,
    backgroundColor: "rgba(0,0,0,0.6)",
    padding: theme.spacing(0.5, 1),
  },
  tooltip: {
    fontSize: '1rem',
    whiteSpace: 'pre-line',
    lineHeight: 1.5,
    maxWidth: 500
  },
  cert: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.success.main,
    background: "rgba(0,0,0,0.65)",
    padding: theme.spacing(0.6),
    fontSize: "2.2rem",
    borderRadius: 9999
  }
}));

const RecordingCard = ({ recording, user, portal, trackId }) => {
  const classes = useStyles();
  // const portal = usePortal();
  const { name, speakers, summary, previewUrl, /* alias, questionsCount, */ ceHours, ceTypeName, id } = recording;
  const speakersString = speakers.map((speaker) => speaker.firstName + " " + speaker.lastName).join(", ");
  // const link = (questionsCount > 0 && alias) ? portal.urlPrefix + `/quizzes/${recording.id}` : null;
  const cdnUrl = previewUrl.replace('https://slidespielweb.azurewebsites.net/', 'https://sspwebapi.azureedge.net/');
  const history = useHistory();
  const { pathname } = useLocation();
  const { recordingId } = useParams();
  const titleRef = useRef();
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const feedback = useFeedback();

  const isMember = user?.isMember;
  let price = isMember ? recording.priceForMembers : recording.price;
  if (!portal.canBuySingleRecording)
    price = null;

  const showDisclaimer = async certPack => {
    const lsName = "disclaimer_" + recording.packageId;
    if (localStorage.getItem(lsName))
      return true;
    if (!ceHours)
      return true;
    const result = await feedback.confirm({ component: (<Disclaimer content={certPack.disclaimer} />), fullWidth: true, confirmButtonText: "Accept", maxWidth: "md", scroll: "paper", clickAwayClose: false, autoFocus: false });
    if (!result)
      return false;
    localStorage.setItem(lsName, 1);
    return result;
  }

  const handleRecordOpen = async () => {
    const certPack = portal.certPacks.find(x => x.id === recording.packageId);
    if (certPack?.disclaimer && recording.alias) {
      const accepted = await showDisclaimer(certPack);
      if (!accepted) return
    }
    if (recordingId)
      history.push(pathname.replace(recordingId, id));
    else
      history.push(pathname + (trackId ? "/" + trackId : "") + "/" + id);
  };

  useEffect(() => {
    if (!titleRef.current)
      return;
    // const element = titleRef.current?.children[0]?.children[0];
    const element = titleRef.current;
    const isEllipsisActive = element.offsetHeight < element.scrollHeight;
    setIsEllipsisActive(isEllipsisActive);
  }, [recording.title]);

  let priceString = formatPrice(price);
  let priceTooltipString = "";
  if (!user && price) {
    priceString = getPriceString(recording.price, recording.priceForMembers);
    priceTooltipString = getPriceTooltipString(recording.price, recording.priceForMembers);
  }

  const subheader = (portal?.hasCertification && (ceHours || ceTypeName)) && (ceHours ? `${ceHours} ${ceTypeName || ""} Credit(s)` : "No " + config.certTitle);

  return (
    <Paper className={classes.root}>
      <div className={classes.media}>
        <img src={cdnUrl} className={classes.image} onClick={handleRecordOpen} />
        {recording.step >= 7 && <Tooltip title="Certificate received" classes={{ tooltip: classes.tooltip }}><CertIcon className={classes.cert} /></Tooltip>}
        {(!recording.alias && price !== null) &&
          <Tooltip title={priceTooltipString} classes={{ tooltip: classes.tooltip }} placement="top">
            {/* <div className={classes.priceSpan} /> */}
            <Typography variant="subtitle1" className={classes.price}>{priceString}</Typography>
          </Tooltip>
        }
        {(!recording.alias && price === null) &&
          <>
            {/* <div className={classes.priceSpan} /> */}
            <Typography variant="subtitle1" className={classes.price}>No Access</Typography>
          </>
        }
        {subheader && <Typography variant="subtitle2" className={classes.subheader}>{subheader}</Typography>}
      </div>
      <Box padding={2}>
        <Tooltip title={isEllipsisActive ? name : ""} classes={{ tooltip: classes.tooltip }}>
          <Typography variant="h6" className={classes.title} onClick={handleRecordOpen} ref={titleRef} gutterBottom>{name}</Typography>
        </Tooltip>
        <Typography variant="subtitle2" gutterBottom component="p" className={classes.speakers} onClick={handleRecordOpen}>
          {speakersString}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p" className={classes.summary} onClick={handleRecordOpen} dangerouslySetInnerHTML={{ __html: parseSummary(summary) }} />
      </Box>
    </Paper>
  );
}

export default inject(({ store }, props) => ({
  portal: store.portal, user: store.currentUser,
}))(RecordingCard);
