import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import RecordingDetails from './RecordingDetails';

const RecordingDetailsDialog = ({ show, recording, handleClose }) => {
  const { name } = recording;
  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="details-dialog-title"
      aria-describedby="details-dialog-description"
      disableScrollLock
    >
      <DialogTitle id="details-dialog-title" style={{ paddingBottom: 0 }}>{name || ""}</DialogTitle>
      <DialogContent>
        <RecordingDetails recording={recording} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RecordingDetailsDialog;