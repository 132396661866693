import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useHistory, /* useLocation, */ Link, useLocation } from "react-router-dom";
import { inject } from "mobx-react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import usePortal from "../hooks/usePortal";
import api from "../../api";
import useFormMetaData from "../hooks/useFormMetaData";
import SupportLink from "../SupportLink";
import EditUserForm from "../forms/EditUserForm";

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  signOut: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    "&:hover": {
      borderColor: theme.palette.error.main,
    }
  }
}));

const excludedFields = ["firstName", "lastName", "email", "ext_p"];

const AccountDialog = ({ user, isLoggingIn, portal }) => {
  const history = useHistory();
  const classes = useStyles();
  const { portalName, urlPrefix } = usePortal();
  const { pathname } = useLocation();
  const [editMode, setEditMode] = React.useState(false);

  console.log("portal products", [...portal.products.map(x => ({ ...x }))])
  const { metaData: portalFormMetaData } = useFormMetaData({ metaDataJson: [portal?.signUpFormMetaData, ...portal.products.map(x => x.signUpFormMetaData)] });

  const userData = useMemo(() => {
    if (!user?.signupFormData)
      return {};
    // console.log("user?.signupFormData", JSON.parse(user.signupFormData));
    return JSON.parse(user.signupFormData);
  }, [user]);

  const userFields = useMemo(() => {
    return Object.keys(userData).filter(x => !excludedFields.includes(x)).map(key => {
      const field = portalFormMetaData?.fields?.find(x => x.id === key);
      if (!field)
        return { key, label: key };
      const { label, options } = field;
      return { key, label, options }
    });
  }, [userData, portalFormMetaData]);

  const handleClose = () => {
    const backUrl = pathname === "/account" ? urlPrefix : pathname.replace("/account", "");
    history.push(backUrl);
  }

  const handleSignOutClick = () => {
    // console.log("urlPrefix", urlPrefix, portalName)
    api.logOut(portalName);
    history.push(urlPrefix || "/");
    window.location.reload();
  }

  return (
    <Dialog open onClose={handleClose} aria-labelledby="account-dialog-title" fullWidth disableScrollLock>
      <DialogTitle id="account-dialog-title">Account</DialogTitle>
      <DialogContent>
        {!editMode ?
          <>
            {!isLoggingIn ?
              <>
                <>
                  <Typography>{user.firstName} {user.lastName}</Typography>
                  <Typography>{user.email}</Typography>
                  {userFields.map(({ key, label, options }) => <Typography key={key}>{label}: {options ? options.find(x => x.value === userData[key])?.label || userData[key] : userData[key]}</Typography>)}
                </>
                {user.role === "manager" &&
                  <Button color="primary" component={Link} to="/admin" variant="contained" className={classes.button}>
                    Manage Portal
                  </Button>
                }
                <Button color="primary" variant="outlined" className={classes.button} onClick={() => setEditMode(true)}>Edit Profile</Button>
                <Button color="secondary" variant="outlined" className={classes.button + " " + classes.signOut} onClick={handleSignOutClick}>Sign Out</Button>
              </> :
              <CircularProgress />
            }
          </> :
          <EditUserForm onClose={() => setEditMode(false)} />
        }
      </DialogContent>
      <DialogActions>
        <SupportLink />
        <div style={{ width: '100%' }} />
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AccountDialog.propTypes = {
  isLoggingIn: PropTypes.bool,
};

export default inject(({ store }, props) => ({
  isLoggingIn: store.isLoggingIn, user: store.currentUser, portal: store.portal
}))(AccountDialog);
