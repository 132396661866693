import React, { useState } from 'react';
import { useFeedback } from '../feedback/Service';
import DataTable from '../DataTable';

const useUploadCsv = ({ validateFunc, uploadFunc, csvCols, validateFuncParams = [], uploadFuncParams = [] }) => {
  const [uploading, setUploading] = useState(false);
  const feedback = useFeedback();

  const upload = async e => {
    const file = e.target.files[0];
    if (!file) return;
    e.target.value = null;
    let result = await uploadCsv(file, validateFunc, ...validateFuncParams);
    if (result?.message) {
      feedback.snackbar({ text: result.message || 'Error uploading file', type: "error" });
      return;
    }
    if (result) {
      const confirmation = await feedback.confirm({ title: "Validation", scroll: "body", maxWidth: "md", fullWidth: true, component: <DataTable cols={csvCols} rows={result} />, confirmButtonText: "Import" });
      if (confirmation) {
        console.log("rest", uploadFuncParams);
        result = await uploadCsv(file, uploadFunc, ...uploadFuncParams);
        if (result)
          feedback.snackbar({ text: "You'll be notified via email on import results.", type: "success" });
      }
    }
  }

  const uploadCsv = async (file, uploadFunc, ...rest) => {
    setUploading(true);
    try {
      const res = await uploadFunc(file, ...rest);
      // feedback.snackbar({ text: 'Upload complete', type: "success" });
      setUploading(false);
      // console.log("res", res);
      return res || true;
    } catch (error) {
      setUploading(false);
      console.warn("Upload csv error", error?.response || error);
      feedback.snackbar({ text: error.response?.data?.message || 'Error uploading file', type: "error" });
    }
  }

  return { isUploading: uploading, upload }
}

export default useUploadCsv;