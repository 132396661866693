import React from "react";
import PropTypes from "prop-types";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import uiConfig from '../../uiConfig';
import { inject } from "mobx-react";

const workflowSteps = [
  { value: 1, label: "Review course details" },
  { value: 2, label: "Answer questions" },
  { value: 3, label: "View Recording" },
  { value: 4, label: "Answer questions" },
  { value: 5, label: "Failed" },
  { value: 6, label: `Get your ${uiConfig.certTitle} certificate` },
  { value: 7, label: `Get your ${uiConfig.certTitle} certificate` },
  { value: 8, label: "Feedback" },
];

const QuizMenu = ({ step, steps, feedbackComplete }) => {
  if (step === 7)
    step = steps[steps.length - 1] === 8 ? 8 : 6;

  if (step === 5)
    steps = [...steps.filter(x => x < 6), 5];

  const items = steps.filter(x => x !== 9).map(x => workflowSteps.find(y => y.value === x));

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const stepIndex = items.findIndex(x => x.value === step);

  console.log({ step, steps: [...steps].join(",") });
  return (
    <Stepper orientation={isSm ? "vertical" : "horizontal"}>
      {items.filter(Boolean).map((item, index) => {
        return (
          <Step key={item.value} active={step === item.value} completed={index < stepIndex || (step === 8 && feedbackComplete) || (step === 6 && steps[steps.length - 1] === 6)}>
            <StepLabel error={item.value === 5}>{item.label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

QuizMenu.propTypes = {
  step: PropTypes.number.isRequired
};

export default inject(({ store }, props) => ({
  steps: store.portal?.steps || [],
}))(QuizMenu);
